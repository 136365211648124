<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<a-form :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-3">
					<a-form-item label="Número de ticket">
						<a-input
							autocomplete="off"
							v-mask="'####'"
							v-decorator="[
								'ticket',
								{
									rules: [
										{
											required: true,
											message: 'Ingresa el número de ticket',
										},
									],
								},
							]"
						/>
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Monto a devolver">
						<a-input prefix="$" autocomplete="off" v-money="money" v-model="amount" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Método de pago">
						<a-select
							placeholder="Selecciona"
							v-decorator="[
								'payment_method',
								{
									rules: [
										{
											required: true,
											message: 'Ingresa el número de ticket',
										},
									],
								},
							]"
						>
							<a-select-option value="1">Efectivo</a-select-option>
							<a-select-option value="2">Digital</a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-3 pt10">
					Documentación asociada
					<br />
					<a-upload name="file" accept="application/pdf,image/*" :file-list="fileList" :remove="handleRemovePrincipal" :before-upload="beforeUploadPrincipal">
						<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
					</a-upload>
				</div>
			</div>
			<div class="row" v-if="form.getFieldValue('payment_method') == 2">
				<div class="col-md-4">
					<a-form-item label="Institución bancaria">
						<a-select show-search option-filter-prop="children" :filter-option="filterOption" :allowClear="true" v-decorator="['bank', { rules: [{ required: true, message: 'Campo requerido' }] }]">
							<a-select-option v-for="element in bankingInstitutionsList" :key="element.id" :value="element.id">{{ element.bank_name }} </a-select-option>
						</a-select>
					</a-form-item>
					<small @click="getBankingInstitutions" class="pointer">Recarga</small>
				</div>
				<div class="col-md-4">
					<a-form-item label="Beneficiario">
						<a-input autocomplete="off" :allowClear="true" v-decorator="['beneficiary', { rules: [{ required: true, message: 'Campo requerido' }] }]" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Cuenta de Pago">
						<a-input autocomplete="off" :allowClear="true" v-mask="'##################'" v-decorator="['account', { rules: [{ required: true, message: 'Campo requerido' }] }]" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3 pt10">
					Comprobante de Pago
					<br />
					<a-upload name="file" accept="application/pdf,image/*" :file-list="filePaymentReceipt" :remove="handleRemovePayment" :before-upload="beforeUploadPayment">
						<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
					</a-upload>
				</div>
			</div>
			<a-button icon="paper-clip" class="btn btn-success" htmlType="submit" ref="formButton" style="display: none">Solicitar factura</a-button>
		</a-form>
	</a-spin>
</template>

<script>
//
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import Swal from 'sweetalert2'
import numeral from 'numeral'

export default {
	name: 'refundComponent',
	directives: {
		mask,
		money: VMoney,
	},
	computed: {
		...mapGetters('bankingInstitutions', ['bankingInstitutionsList']),
		...mapGetters('cashFlow', ['spinnerLoader', 'spinnerLoaderLabel']),
		...mapGetters('user', ['user']),
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			amount: 0,
			fileList: [],
			filePaymentReceipt: [],
		}
	},
	beforeMount() {
		if (!this.bankingInstitutionsList.length) {
			this.getBankingInstitutions()
		}
	},
	methods: {
		resetVariables() {
			this.filePaymentReceipt = []
			this.fileList = []
			this.amount = 0
			this.form.resetFields()
		},
		getBankingInstitutions() {
			this.$store.dispatch('bankingInstitutions/GET')
		},
		handleSubmit(e) {
			e.preventDefault()

			let errors = []

			if (this.filePaymentReceipt.length == 0) {
				errors.push(`- Debes adjuntar comprobante de pago`)
			}

			if (numeral(this.amount).value() == 0) {
				errors.push(`- Debes ingresar el monto a devolver`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}

			this.form.validateFields((err, values) => {
				if (!err) {
					//
					let payment_method = numeral(values.payment_method).value()

					let payload = {
						ticket: values.ticket,
						cash_flow_type: 'out',
						cashflow_action_id: 18, // Cancelación/Devolución de ticket de venta
						cashflow_description: `Cancelación/Devolución de ticket de venta #${values.ticket}`,
						issuing: this.user.user_id,
						cash: payment_method == 2 ? 0 : numeral(this.amount).value(),
						bank_transfer: payment_method == 1 ? 0 : numeral(this.amount).value(),
						other: '',
						comments: `Cancelación/Devolución de ticket de venta #${values.ticket}`,
					}

					this.$store.dispatch('cashFlow/CREATE', { payload, fileList: this.fileList, filePaymentReceipt: this.filePaymentReceipt }).then(() => {
						this.$emit('closeModal')
					})
				}
			})
		},
		handleRemovePrincipal() {
			this.fileList = []
		},
		handleRemovePayment() {
			this.filePaymentReceipt = []
		},
		beforeUploadPrincipal(file) {
			this.fileList = [file]
			return false
		},
		beforeUploadPayment(file) {
			this.filePaymentReceipt = [file]
			return false
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onSaveRecord() {
			this.$refs.formButton.$el.click()
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>